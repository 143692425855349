// import react stuff
import React from 'react';
import useScreenSize from '../../../hooks/useScreenSize';

// import table utils
import ResponsiveTable from '../utils/ResponsiveTable';
import cols from '../utils/ColumnsDict';
import { dDict } from '../../../harddata/DataDictionary';
import {
    getShotContextCols,
    getChncContextCols,
    getAstdUnastdCols,
    getDefensiveCols,
    getPbpFoulsCols,
    getMiscScoringCols
} from '../utils/ColumnGroups';

// Import Column Groups
import { allCompetitionIds } from '../../../harddata/NcaaStructures';


// function ReactTable7Table({ tableData, datasetScope, isConf }) {
function TeamGameStatsTable({
    tableData,
    tableType,
    features = ['ratingsBar', 'pagination', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton'],
    lhsCols = [],
    hideFilters = false,
    infoModal = 'teamGameRecaps', // [teamGameRecaps, confGameRecaps, gameLanding, gameOverview, tourneyGameRecaps]
    initialPageSize = 10,
    zoneSchema = 'zones6',
    statsArray = [], // for DIY (select your own columns) tableType
    sortObj = null
}) {
    // console.log('TeamGameStatsTable props: ', { tableData, tableType, statsArray, sortObj });

    // Set Constants
    const competitionId = tableData?.[0]?.competitionId || null;
    const isDefense = tableData?.[0]?.isOffense === false;
    const isMale = allCompetitionIds.male.includes(competitionId);
    const isFromPbp = ['shotregion', 'shotContextHcTrPb', 'chncContextHcTrPb', 'astdUnastd', 'pbpFouls'].includes(tableType);

    // Create Configs - these get passed as 'config' parameter to columns
    const mainConfig = { ptgc: 'team', isOffense: true, hideFilter: hideFilters };
    const left10MainConfig = { ptgc: 'team', isOffense: true, hideFilter: hideFilters, className: 'border-left pad-left-10' };
    const cfgW35 = { ...mainConfig, width: 35 };
    const cfgW40 = { ...mainConfig, width: 40 };


    // Are default columns (lhs) sticky left? based on screen size.
    const { isMd } = useScreenSize();
    const isStickyLeft = isMd ? false : true;


    // Default Left-Most Columns
    const lhsColsColumns = lhsCols.map(col => {
        let outputCol;
        switch (col) {
            case 'dayOfWeek': outputCol = cols.dayOfWeek; break;
            case 'gameDate': outputCol = cols.gameDate; break;
            case 'winLossRecord': outputCol = cols.winLossRecord('sticky-left'); break;
            case 'gameBox': outputCol = cols.gameBox; break;
            case 'winOrLoss': { // show "W" or "L"
                let ptsScoredKey = isFromPbp ? 'ptsScoredBox' : 'ptsScored';
                let ptsAgstKey = isFromPbp ? 'ptsAgstBox' : 'ptsAgst';
                if (isDefense) { [ptsScoredKey, ptsAgstKey] = [ptsAgstKey, ptsScoredKey]; } // flip keys if defense so that W-L is correct for team
                outputCol = cols.winOrLoss(ptsScoredKey, ptsAgstKey);
                break;
            }
            case 'ptsScored': {
                let columnConfig = { ...mainConfig, header: 'Tm', hideFilter: hideFilters, width: 30, unit: 'int', tip: 'Points Scored AAA' };
                let ptsScoredKey = isFromPbp ? 'ptsScoredBox' : 'ptsScored';
                ptsScoredKey = isDefense ? ptsScoredKey.replace('Scored', 'Agst') : ptsScoredKey;
                outputCol = cols.valueOnly(ptsScoredKey, columnConfig);
                break;
            }
            case 'ptsAgst': {
                let columnConfig = { ...mainConfig, header: 'Opp', hideFilter: hideFilters, unit: 'int', width: 30 };
                let ptsAgstKey = isFromPbp ? 'ptsAgstBox' : 'ptsAgst';
                ptsAgstKey = isDefense ? ptsAgstKey.replace('Agst', 'Scored') : ptsAgstKey;
                outputCol = cols.valueOnly(ptsAgstKey, columnConfig);
                break;
            }
            case 'teamLogo': outputCol = cols.teamLogo({ idKey: 'teamId' }); break;
            case 'teamLogoAgst': outputCol = cols.teamLogo({ idKey: 'teamIdAgst' }); break;
            case 'conferenceLogo': outputCol = cols.conferenceLogo({ idKey: 'conferenceId' }); break;
            case 'isHome': outputCol = cols.isHome(); break;
            case 'teamMarket': outputCol = cols.teamMarket({ acc: 'teamMarket', header: 'Team', width: 120, filter: true }); break;
            case 'teamMarketAgst': outputCol = cols.teamMarket({ acc: 'teamMarketAgst', header: 'Opp', width: 120, filter: true }); break;
            case 'vs': outputCol = cols.textField('vs', 15); break;
            case 'period': outputCol = cols.period({}); break;

                // cols.textLabel({ acc: 'cptsScored', header: '', width: 28 }),
                // cols.textLabel({ acc: 'ptsAgst', header: 'Opp', width: 28 }),

            default: console.log('unrecognized lhsCol in TeamAggStatsTable: ', col);
        }

        return outputCol;
    });

    const defaultCols = {
        Header: ' ',
        ...(isStickyLeft ? { sticky: 'left' } : {}),
        ...(isStickyLeft ? { className: 'sticky-left left-last' } : {}),
        columns: lhsColsColumns
    };
    // ========


    // Column Groups
    // ================
    // Traditional (Shooting, Boxscore) Stats
    const summaryCols = [
        defaultCols,
        { Header: 'Scoring by Period', columns: [
            cols.blankCol({ width: 10 }),
            ...(!isMale ? [cols.valueOnly('ptsP1', { header: 'Q1', width: 42 })] : []), // womens game, 4 quarters
            ...(!isMale ? [cols.valueOnly('ptsP2', { header: 'Q2', width: 42 })] : []),
            ...(!isMale ? [cols.valueOnly('ptsP3', { header: 'Q3', width: 42 })] : []),
            ...(!isMale ? [cols.valueOnly('ptsP4', { header: 'Q4', width: 42 })] : []),
            ...(isMale ? [cols.valueOnly('ptsP1', { header: 'H1', width: 42 })] : []), // mens game, 2 halves
            ...(isMale ? [cols.valueOnly('ptsP2', { header: 'H2', width: 42 })] : []),
            cols.valueOnly('ptsOt', { header: 'OT', width: 42 })
        ] },
        { Header: 'Scoring Types', className: 'border-left pad-left-10', columns: [
            cols.blankCol({ width: 10 }),
            cols.valueAndRating('potov', { header: 'Off TOV', ptgc: 'team' }),
            cols.valueAndRating('pitp', { header: 'In Paint', ptgc: 'team' }),
            cols.valueAndRating('scp', { header: '2nd Chnc', ptgc: 'team' }),
            cols.valueAndRating('fbpts', { header: 'Fast Break', ptgc: 'team' }),
            cols.valueAndRating('benchPts', { header: 'Bench' })
        ] },
        { Header: 'Leads, Ties and Runs', className: 'border-left', columns: [
            cols.valueOnly('timesTied', { ...left10MainConfig, width: 60, hideFilter: true }),
            cols.valueOnly('leadChanges', { ...mainConfig, width: 60, hideFilter: true }),
            // cols.valueOnly('timeLeading', { ...mainConfig, width: 60, hideFilter: true }),
            cols.minutesToClock({ acc: 'timeLeading', header: 'Time Leading', width: 60 }),
            cols.valueOnly('biggestRun', { ...mainConfig, width: 60, hideFilter: true }),
            cols.valueOnly('biggestLead', { ...mainConfig, width: 60, hideFilter: true }),
            cols.textLabel({ acc: 'biggestLeadScore', header: 'Largest Margin', width: 60 })
        ] },
        { Header: ' ', className: 'border-left', columns: [
            cols.valueAndRating('netRtg', left10MainConfig)
        ] }
    ];

    const tradboxCols = [
        defaultCols,
        {
            Header: 'Traditional Box Score', columns: [
                cols.valueAndRating('fgPct', mainConfig),
                cols.valueAndRating('fg2Pct', mainConfig),
                cols.valueAndRating('fg3Pct', mainConfig),
                cols.valueAndRating(`ast`, { ...mainConfig, width: 70, className: 'border-left' }),
                cols.valueAndRating(`orb`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`drb`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`reb`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`stl`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`blk`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`tov`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`pf`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`opf`, { ...mainConfig, width: 70 }),
                cols.valueAndRating(`pfd`, { ...mainConfig, width: 70 })
            ]
        }
    ];
    const tradshotCols = [
        defaultCols,
        {
            Header: 'Advanced', columns: [
                cols.valueAndRating('efgPct', mainConfig),
                cols.valueAndRating('tsPct', mainConfig),
                cols.valueAndRating('fga3Rate', mainConfig)
            ]
        }, {
            Header: 'Field Goals', className: 'border-left', columns: [
                cols.valueOnly(`fgm`, { ...left10MainConfig, showFilter: true }),
                cols.valueOnly(`fga`, { ...mainConfig, showFilter: true }),
                cols.valueAndRating('fgPct', mainConfig)
            ]
        }, {
            Header: '2-Point FGs', className: 'border-left', columns: [
                cols.valueOnly(`fgm2`, { ...left10MainConfig, showFilter: true }),
                cols.valueOnly(`fga2`, { ...mainConfig, showFilter: true }),
                cols.valueAndRating('fg2Pct', mainConfig)
            ]
        }, {
            Header: '3-Point FGs', className: 'border-left', columns: [
                cols.valueOnly(`fgm3`, { ...left10MainConfig, showFilter: true }),
                cols.valueOnly(`fga3`, { ...mainConfig, showFilter: true }),
                cols.valueAndRating('fg3Pct', mainConfig)
            ]
        }, {
            Header: 'Free Throws', className: 'border-left', columns: [
                cols.valueOnly(`ftm`, { ...left10MainConfig, showFilter: true }),
                cols.valueOnly(`fta`, { ...mainConfig, showFilter: true }),
                cols.valueAndRating('ftPct', mainConfig)
            ]
        }
    ];

    const gameOverviewCols = [
        defaultCols,
        {
            Header: 'Box Score', className: 'border-left pad-left-10', columns: [
                cols.valueOnly(`ptsScored`, { ...left10MainConfig, width: 28 }),
                cols.valueOnly(`ast`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`orb`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`drb`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`stl`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`blk`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`tov`, { ...mainConfig, width: 28 }),
                cols.valueOnly(`pf`, { ...mainConfig, width: 28 })
            ]
        }, {
            Header: 'Shooting', className: 'border-left pad-left-10', columns: [
                cols.fgmFga({ fgmKey: 'fgm2', fgaKey: 'fga2', header: '2PM/A', className: 'border-left pad-left-10' }),
                cols.valueOnly(`fg2Pct`, { ...mainConfig, width: 44 }),
                cols.fgmFga({ fgmKey: 'fgm3', fgaKey: 'fga3', header: '3PM/A' }),
                cols.valueOnly(`fg3Pct`, { ...mainConfig, width: 44 }),
                cols.fgmFga({ fgmKey: 'ftm', fgaKey: 'fta', header: 'FTM/A' }),
                cols.valueOnly(`ftPct`, { ...mainConfig, width: 44 })
            ]
        }
    ];

    // Advanced Stats
    const offensiveCols = [
        defaultCols,
        cols.valueAndRating('ortg', left10MainConfig),
        {
            Header: 'Shooting', className: 'border-left', columns: [
                cols.valueAndRating('tsPct', left10MainConfig),
                cols.valueAndRating('fg2Pct', mainConfig),
                cols.valueAndRating('fg3Pct', mainConfig),
                cols.valueAndRating('ftPct', mainConfig)
            ]
        }, {
            Header: 'Ball Control', className: 'border-left', columns: [
                cols.valueAndRating('astPct', left10MainConfig),
                cols.valueAndRating('astRatio', mainConfig),
                cols.valueAndRating('tovPct', mainConfig),
                cols.valueAndRating('astTov', mainConfig)
            ]
        }, {
            Header: 'Play Style', className: 'border-left', columns: [
                cols.valueAndRating('orbPct', left10MainConfig),
                cols.valueAndRating('ftaRate', mainConfig),
                cols.valueAndRating('fga3Rate', mainConfig),
                cols.valueAndRating('pace', mainConfig)
            ]
        }
    ];

    const fourfactCols = [
        defaultCols,
        cols.valueAndRating('netRtg', left10MainConfig),
        {
            Header: 'Team 4-Factors', className: 'border-left', columns: [
                cols.valueAndRating('ortg', left10MainConfig),
                cols.valueAndRating('efgPct', mainConfig),
                cols.valueAndRating('orbPct', mainConfig),
                cols.valueAndRating('ftaRate', mainConfig),
                cols.valueAndRating('tovPct', mainConfig)
            ]
        }, {
            Header: 'Opponent 4-Factors', className: 'border-left', columns: [
                cols.valueAndRating('drtg', left10MainConfig),
                cols.valueAndRating('efgPctAgst', { ...mainConfig, isOffense: false, baseKey: 'efgPct' }),
                cols.valueAndRating('orbPctAgst', { ...mainConfig, isOffense: false, baseKey: 'orbPct' }),
                cols.valueAndRating('ftaRateAgst', { ...mainConfig, isOffense: false, baseKey: 'ftaRate' }),
                cols.valueAndRating('tovPctAgst', { ...mainConfig, isOffense: false, baseKey: 'tovPct' })
            ]
        }
    ];

    const gameLandingCols = [
        {
            Header: 'Game Results', sticky: 'left', className: 'sticky-left left-last', columns: [
                cols.dayOfWeek,
                cols.gameDate,
                cols.gameBox,
                cols.textLabel({ acc: 'ptsScored', header: 'Tm', width: 24 }),
                cols.teamMarket({ acc: 'teamMarket', header: 'Home Team', width: 110, className: 'sticky-left' }),
                cols.textLabel({ acc: 'ptsAgst', header: 'Op', width: 24 }),
                cols.marketAway({ className: 'sticky-left left-last' })
            ]
        }, {
            Header: 'Home Four Factors', className: 'pad-left-10', columns: [
                cols.teamLogo({ idKey: 'teamId', className: 'pad-left-10' }),
                cols.valueAndRating('ortg', mainConfig),
                cols.valueAndRating('efgPct', mainConfig),
                cols.valueAndRating('orbPct', mainConfig),
                cols.valueAndRating('tovPct', mainConfig),
                cols.valueAndRating('ftaRate', mainConfig)
            ]
        }, {
            Header: 'Away Four Factors', className: 'border-left pad-left-10', columns: [
                cols.teamLogo({ idKey: 'teamIdAgst', className: 'border-left pad-left-10' }),
                cols.valueAndRating('ortgAway', mainConfig),
                cols.valueAndRating('efgPctAway', mainConfig),
                cols.valueAndRating('orbPctAway', mainConfig),
                cols.valueAndRating('tovPctAway', mainConfig),
                cols.valueAndRating('ftaRateAway', mainConfig)
            ]
        }
    ];

    // Shooting By Zone Columns
    const zones6Cols = [
        defaultCols,
        {
            Header: 'At The Rim (u4.5 ft)', className: 'border-left', columns: [
                cols.valueOnly(`atr2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`atr2Fga`, { ...mainConfig }),
                cols.valueAndRating('atr2FgPct', { ...mainConfig }),
                cols.valueAndRating('atr2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'In The Paint', className: 'border-left', columns: [
                cols.valueOnly(`paint2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`paint2Fga`, { ...mainConfig }),
                cols.valueAndRating('paint2FgPct', { ...mainConfig }),
                cols.valueAndRating('paint2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Mid Range 2s', className: 'border-left', columns: [
                cols.valueOnly(`mid2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`mid2Fga`, { ...mainConfig }),
                cols.valueAndRating('mid2FgPct', { ...mainConfig }),
                cols.valueAndRating('mid2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Above Break 3s', className: 'border-left', columns: [
                cols.valueOnly(`atb3Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`atb3Fga`, { ...mainConfig }),
                cols.valueAndRating('atb3FgPct', { ...mainConfig }),
                cols.valueAndRating('atb3FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Corner 3s', className: 'border-left', columns: [
                cols.valueOnly(`c3Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`c3Fga`, { ...mainConfig }),
                cols.valueAndRating('c3FgPct', { ...mainConfig }),
                cols.valueAndRating('c3FgaFreq', { ...mainConfig })
            ]
        }
    ];
    const dists7Cols = [
        defaultCols,
        {
            Header: 'At The Rim (u4.5 ft)', className: 'border-left', columns: [
                cols.valueOnly(`atr2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`atr2Fga`, { ...mainConfig }),
                cols.valueAndRating('atr2FgPct', { ...mainConfig }),
                cols.valueAndRating('atr2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Short 2s (4.5 - 10 ft)', className: 'border-left', columns: [
                cols.valueOnly(`sht2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`sht2Fga`, { ...mainConfig }),
                cols.valueAndRating('sht2FgPct', { ...mainConfig }),
                cols.valueAndRating('sht2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Medium 2s (10 - 15 ft)', className: 'border-left', columns: [
                cols.valueOnly(`med2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`med2Fga`, { ...mainConfig }),
                cols.valueAndRating('med2FgPct', { ...mainConfig }),
                cols.valueAndRating('med2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Long 2s (15+ ft)', className: 'border-left', columns: [
                cols.valueOnly(`lng2Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`lng2Fga`, { ...mainConfig }),
                cols.valueAndRating('lng2FgPct', { ...mainConfig }),
                cols.valueAndRating('lng2FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Short 3s (u25 ft)', className: 'border-left', columns: [
                cols.valueOnly(`sht3Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`sht3Fga`, { ...mainConfig }),
                cols.valueAndRating('sht3FgPct', { ...mainConfig }),
                cols.valueAndRating('sht3FgaFreq', { ...mainConfig })
            ]
        }, {
            Header: 'Long 3s (25+ ft)', className: 'border-left', columns: [
                cols.valueOnly(`lng3Fgm`, { ...left10MainConfig }),
                cols.valueOnly(`lng3Fga`, { ...mainConfig }),
                cols.valueAndRating('lng3FgPct', { ...mainConfig }),
                cols.valueAndRating('lng3FgaFreq', { ...mainConfig })
            ]
        }
    ];

    const diyColumns = statsArray.map(row => {
        // check if we have percentiles
        const pctilesArray = tableData.map(d => d[`${row.key}Pctile`]).filter(d => d !== null && typeof d !== 'undefined');
        const hasPctiles = pctilesArray.length > 0;

        // determine column width (when no percentiles)
        const headerChars = dDict?.[row.key]?.label1?.length || 4;
        const width = 12 + (headerChars * 9);

        // return columns
        return hasPctiles
            ? cols.valueAndRating(row.key, mainConfig)
            : cols.valueOnly(row.key, { ...mainConfig, width });
    });
    const diyCols = [
        defaultCols,
        { Header: diyColumns.length >= 2 ? 'Team Stats' : ' ', className: 'border-left pad-left-10', columns: [
            cols.blankCol({ width: 12 }),
            ...diyColumns,
            cols.blankCol({ width: 5 })
        ] }
    ];


    // Defense Columns
    const defensiveCols = [
        defaultCols,
        cols.valueAndRating('drtg', left10MainConfig),
        ...getDefensiveCols({ ptgc: 'teamGame', isOffense: !isDefense, isTotals: true, ratingsType: 'pctiles', perWhat: '' })
    ];

    // Misc Scoring Columns
    const miscScoringCols = [
        defaultCols,
        ...getMiscScoringCols({ ptgc: 'teamGame', isOffense: !isDefense, isTotals: true, ratingsType: 'pctiles', perWhat: '' })
    ];


    // Other PBP Data Derived Column Groups
    const shotContextHcTrPbCols = [
        defaultCols,
        ...getShotContextCols({ ptgc: 'teamGame', isOffense: !isDefense })
    ];

    const chncContextHcTrPbCols = [
        defaultCols,
        ...getChncContextCols({ ptch: 'teamGame', isOffense: !isDefense })
    ];

    const astdUnastdCols = [
        defaultCols,
        ...getAstdUnastdCols({ isTotals: true, ratingsType: 'pctiles', isMale })
    ];

    const pbpFoulsCols = [
        defaultCols,
        ...getPbpFoulsCols({ isTotals: true, ratingsType: 'pctiles' })
    ];

    // individual team-streaks
    const streaksCols = tableType !== 'streaks' ? [] : [
        // not "teamAgg", but rather a row for each streak
        defaultCols,
        { Header: 'Streak Info', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('streakSize', { header: 'Run', unit: 'int', width: 35, className: 'border-left pad-left-10' }),
            // cols.valueOnly('secs', { header: 'Secs', unit: 'int', width: 45 }),
            cols.secondsToClock({ acc: 'secs', header: 'Secs', width: 50, clockType: 'totalSecs', tipTitle: 'Streak Length of Time', tipDesc: 'In minutes & seconds formatting, the total amount of time passed throughout the duration of the scoring streak.' }),
            cols.secondsToClock({ acc: 'secsInStart', header: 'Start', width: 72, tipTitle: 'Clock at Start of Streak', tipDesc: 'Time on the clock when team on run scored their first points in the run.' }),
            cols.textLabel({ acc: 'scoreStart', header: 'Score', width: 58, tipTitle: 'Score at Start of Streak' }),
            cols.secondsToClock({ acc: 'secsInEnd', header: 'End', width: 72, tipTitle: 'Clock at Start of Streak', tipDesc: 'Time on the clock when team on run scored their final points in the run.' }),
            cols.textLabel({ acc: 'scoreEnd', header: 'Score', width: 58, tipTitle: 'Score at End of Streak' })
        ] },
        { Header: 'Team Shooting', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('fgm2', { ...cfgW35, className: 'border-left pad-left-10' }),
            cols.valueOnly('fga2', cfgW35),
            cols.valueOnly('fg2Pct', cfgW40),
            cols.valueOnly('fgm3', { ...cfgW35, className: 'pad-left-10' }),
            cols.valueOnly('fga3', cfgW35),
            cols.valueOnly('fg3Pct', cfgW40)
        ] },
        { Header: 'Team Stats', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ast', { ...cfgW35, className: 'border-left pad-left-10' }),
            cols.valueOnly('orb', cfgW35),
            cols.valueOnly('drb', cfgW35),
            cols.valueOnly('stl', cfgW35),
            cols.valueOnly('blk', cfgW35),
            cols.valueOnly('tov', cfgW35),
            cols.valueOnly('pf', cfgW35)
        ] },
        { Header: 'Opponent Stats', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('orbAgst', { ...cfgW35, header: 'ORB', className: 'border-left pad-left-10' }),
            cols.valueOnly('drbAgst', { ...cfgW35, header: 'DRB' }),
            cols.valueOnly('stlAgst', { ...cfgW35, header: 'STL' }),
            cols.valueOnly('blkAgst', { ...cfgW35, header: 'BLK' }),
            cols.valueOnly('tovAgst', { ...cfgW35, header: 'TOV' }),
            cols.valueOnly('pfAgst', { ...cfgW35, header: 'PF' })
        ] }
        // { Header: 'Stat Margins', className: 'border-left pad-left-10', columns: [
        //     cols.valueOnly('fgaDiff', cfgW45),
        //     cols.valueOnly('ftaDiff', cfgW45),
        //     cols.valueOnly('tovDiff', cfgW45),
        //     cols.valueOnly('rebDiff', cfgW45)
        // ] }
    ];


    // Switch Between Columns
    const columns = React.useMemo(
        () => {
            switch (tableType) {
                case 'summary': return summaryCols;
                case 'fourfact': return fourfactCols;
                case 'tradbox': return tradboxCols;
                case 'tradshot': return tradshotCols;
                case 'offensive': return offensiveCols;
                case 'defensive': return defensiveCols;
                case 'miscScoring': return miscScoringCols;
                case 'gameLanding': return gameLandingCols;
                case 'streaks': return streaksCols;
                case 'shotregion': return zoneSchema === 'zones6' ? zones6Cols : dists7Cols;
                case 'gameOverview': return gameOverviewCols;
                case 'shotContextHcTrPb': return shotContextHcTrPbCols;
                case 'chncContextHcTrPb': return chncContextHcTrPbCols;
                case 'astdUnastd': return astdUnastdCols;
                case 'pbpFouls': return pbpFoulsCols;
                case 'diy': return diyCols;
                default: return [];
            }
        },
        [tableData, tableType, zoneSchema]
    );

    // handle sort object
    const sortObject = React.useMemo(() => {
        // Return explicit sort object if provided
        if (sortObj) { return sortObj; }

        // Default sort configurations
        const sortConfigs = {
            gameOverview: { id: 'ptsScored', desc: true },
            streaks: { id: 'streakSize', desc: true },
            default: { id: 'gameDate', desc: true }
        };
        // and return the appropriate sort object
        return sortConfigs[tableType] || sortConfigs.default;
    }, [tableType, sortObj]);

    // Which info modal content to include in the (i)
    let thisInfoModal = ['shotregion'].includes(tableType) ? `${infoModal}Pbp` : `${infoModal}Box`;
    thisInfoModal = infoModal === 'gameOverview' ? null : thisInfoModal;
    thisInfoModal = features.includes('infoModal') ? thisInfoModal : null;
    return (
        <ResponsiveTable
            columns={columns}
            tableData={tableData}
            features={features}
            defaultSortObj={sortObject}
            initialPageSize={initialPageSize}
            infoModal={thisInfoModal}
        />
    );
}

export default TeamGameStatsTable;
