import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Button, Modal, FormControl, Row, Col, Container } from 'react-bootstrap';
import Axios from 'axios';
import config from '../../config';

import GlobalContext from '../../context/GlobalContext';

function SignUpButton({ handleSignUp }) {
    return (
        <p style={{ margin: '8px 0 0 0', fontSize: 14, color: 'grey', lineHeight: 1.4 }}>
            Not a member?
            <button
                className='clickable'
                onClick={() => handleSignUp()}
                style={{ color: `#0066CC`, paddingLeft: 3, border: 'none', background: 'none' }}
            >
                <u>{`Sign Up`}</u>
            </button>
        </p>
    );
}

function ForgotPasswordButton({ handleForgotPassword }) {
    return (
        <p style={{ margin: 0, fontSize: 14, color: 'grey', lineHeight: 1.4 }}>
            Forgot your password?
            <button
                className='clickable'
                onClick={() => handleForgotPassword()}
                style={{ color: `#0066CC`, paddingLeft: 3, border: 'none', background: 'none' }}
            >
                <u>{`Reset it`}</u>
            </button>
        </p>
    );
}

function SendNewEmailButton({ handleSendNewEmail }) {
    return (
        <p style={{ margin: 0, fontSize: 14, color: 'grey', lineHeight: 1.4 }}>
            Verify email address?
            <button
                className='clickable'
                onClick={() => handleSendNewEmail()}
                style={{ color: `#0066CC`, paddingLeft: 3, border: 'none', background: 'none' }}
            >
                <u>{`Click Here`}</u>
            </button>
        </p>
    );
}

function LoginModal({ showLogin, setShowLogin }) {
    // console.log('LoginModal Params: ', { showLogin, setShowLogin });

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    // useState: Create State
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginWithLink, setLoginWithLink] = useState(false);
    const [notice, setNotice] = useState(null);
    const [error, setError] = useState(null);
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [continued, setContinued] = useState(false);

    // useContext
    const { setUserData } = useContext(GlobalContext);

    // useEffect: set form errors
    useEffect(() => {
        let isCurrentEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        let isCurrentPasswordValid = password.length >= 6;
        let latestFormErrors = formErrors;
        latestFormErrors.email = isCurrentEmailValid ? '' : ' is invalid';
        latestFormErrors.password = isCurrentPasswordValid ? '' : ' is invalid';
        setFormErrors(latestFormErrors);
    }, [email, password]);


    const handleForgotPassword = () => {
        setShowLogin(false);
        navigate('/reset-password');
    };
    const handleSendNewEmail = () => {
        setShowLogin(false);
        navigate('/send-verification-email');
    };
    const handleSignUp = () => {
        setShowLogin(false);
        navigate('signup');
    };

    const handleLoginWithLink = async (e) => {
        setIsLoggingIn(true);
        e.preventDefault();

        try {
            const loginUser = { email };
            const apiBaseUrl = config.url.API_URL;
            await Axios.post(`${apiBaseUrl}/users/send-login-link`, loginUser);
            setNotice('Please check your email for a login link.');
            setTimeout(() => setNotice(null), 10000);
            setIsLoggingIn(false);
        } catch (err) {
            setIsLoggingIn(false);
            setError(err.response?.data?.msg || 'Error Logging In');
            setTimeout(() => setError(null), 3000);
        }
    };

    // Handle Clicking "Sign In" Button
    const handleLoginWithPassword = async (e) => {
        setIsLoggingIn(true);
        e.preventDefault();
        try {
            // Make Axios Login Request
            const loginUser = { email, password };
            const apiBaseUrl = config.url.API_URL;
            const loginResponse = await Axios.post(`${apiBaseUrl}/users/login`, loginUser);
            // add logging here, to track who is loggin into the website

            // Set User As Logged In + Save Token To LocalStorage
            setUserData({
                token: loginResponse.data.token,
                user: loginResponse.data.user
            });
            localStorage.setItem('auth-token', loginResponse.data.token); // could store session token in memory, more secure than localStorage

            // Add Event Log Login
            let userId = loginResponse?.data?.user?._id;
            let userEmail = loginResponse?.data?.user?.email;
            let newEventLog = { userId, email: userEmail, type: 'login' };
            await Axios.post(`${apiBaseUrl}/users/add-event-log`, newEventLog);

            // update users lastLoginAt
            await Axios.post(`${apiBaseUrl}/users/update-last-login`, { userId });

            // Set States Following Successful Login
            setIsLoggingIn(false);
            setShowLogin(false);
            setEmail('');
            setPassword('');

            // Redirect to home if logging in from either of these routes
            if (['/verify-email-page/success', '/signup'].includes(path)) {
                navigate('/');
            }
        } catch (err) {
            setIsLoggingIn(false);
            setError(err.response?.data?.msg || 'Error Logging In');
            setTimeout(() => setError(null), 3000);
        }
    };

    // Main Login Handler, Calls Either Link or Password Login
    const handleLogin = async (e) => {
        try {
            if (loginWithLink) {
                handleLoginWithLink(e);
            } else {
                handleLoginWithPassword(e);
            }
        } catch (err) {
            setError(err.response?.data?.msg || 'Error Logging In');
            setTimeout(() => setError(null), 3000);
        }
    };


    // Save Buttons, Links
    const continueButton =
        (<Button
            style={{ backgroundColor: '#0066CC', fontWeight: '700' }}
            type='submit'
            onClick={() => setContinued(true)}
            block
        >
            Continue
        </Button>);
    const signInButton =
        (<Button
            style={{ backgroundColor: '#0066CC', fontWeight: '700' }}
            type='submit'
            onClick={(e) => handleLogin(e)}
            block
        >
            {!isLoggingIn ? 'Sign In' : 'Signing In...'}
        </Button>);
    const emailForm =
        (<FormControl
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            name='email'
            placeholder='E-mail'
            style={{ marginBottom: '8px' }}
        />);
    const passwordForm =
        (<FormControl
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            placeholder='Password'
            style={{ marginBottom: '8px' }}
            hidden={loginWithLink}
        />);
    const signInMethod =
        (<p
            onClick={() => setLoginWithLink(!loginWithLink)}
            style={{ color: '#222222', textDecoration: 'underline double #0066CC', cursor: 'pointer', marginTop: 8 }}
        >
            {loginWithLink ? 'Sign In With Password' : 'Sign In Without Password'}
        </p>);
    const onlyEmailText =
        (<p style={{ fontSize: 14, color: 'grey', lineHeight: 1.1, marginTop: 8 }}>
            Passwordless login will send a link to your email address that you can click to log back into your account.
        </p>);

    return (
        <Modal
            show={showLogin}
            onHide={() => setShowLogin(false)}
            size='sm'
        >
            <Modal.Header style={{ backgroundColor: '#DDDDDD', color: '#222222', padding: '0.5rem 1rem' }} closeButton>
                <Modal.Title style={{ fontWeight: 700, fontSize: '1.4em' }}>Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12}>
                            {emailForm}
                            {continued === false && continueButton}
                            {continued === true && passwordForm}
                            {continued === true && signInButton}
                            {continued === true && loginWithLink === true && onlyEmailText}
                            {continued === true && signInMethod}

                            {(error && error.length > 0) &&
                                <Alert className='modal-alert' variant={'danger'}>
                                    {error}
                                </Alert>
                            }
                            {(notice && notice.length > 0) &&
                                <Alert className='modal-alert' variant={'success'} style={{ marginTop: 8, padding: '8px 12px' }}>
                                    {notice}
                                </Alert>
                            }

                            <SignUpButton handleSignUp={handleSignUp} />
                            <ForgotPasswordButton handleForgotPassword={handleForgotPassword} />
                            <SendNewEmailButton handleSendNewEmail={handleSendNewEmail} />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
