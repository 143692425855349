
import React from 'react';
import cols from '../utils/ColumnsDict';
import ResponsiveTable from '../utils/ResponsiveTable';
import useScreenSize from '../../../hooks/useScreenSize';
import { dDict } from '../../../harddata/DataDictionary';
import { allCompetitionIds } from '../../../harddata/NcaaStructures';
import {
    getDefensiveCols,
    getMiscScoringCols
} from '../utils/ColumnGroups';

const PlayerGameStatsTable = ({
    tableData,
    tableType,
    tableTypeConfig = { subType: null, shotZone: null, clockInterval: null },
    features = ['ratingsBar', 'pagination', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton'],
    lhsCols = [],
    initialPageSize = 10,
    statsArray = [], // for DIY (select your own columns) tableType
    pageType,
    sortObj = null // overrides the sortObject
}) => {
    // console.log('PlayerGameStatsTable props: ', { tableData, tableType, statsArray, pageType, sortObj, tableTypeConfig });

    // setup constants
    const pbpTableTypes = ['shotregion', 'byShotClock', 'tradshotPbp', 'assistregion', 'rebByFgaFta', 'freeThrows', 'pbpFouls', 'astdUnastd', 'tyingGoAhead', 'shotContextHcTrPb', 'chncContextHcTrPb', 'fga3Pbp', 'rebByZone', 'pace'];
    const isFromPbp = pbpTableTypes.includes(tableType) ? true : false;
    let isMinsPbp = ['zones6', 'dists7', 'assistregion', 'rebByFgaFta', 'tradshotPbp', 'freeThrows', 'pbpFouls'].includes(tableType);
    let isMale = allCompetitionIds.male.includes(tableData?.[0]?.competitionId || 999999);


    // Default Left-Most Columns
    const lhsColsColumns = lhsCols.map(col => {
        let outputCol;
        switch (col) {
            case 'gameDate': outputCol = cols.gameDate; break;
            case 'winLossRecord': outputCol = cols.winLossRecord(''); break;
            case 'gameBox': outputCol = cols.gameBox; break;
            case 'winOrLoss': outputCol = cols.winOrLoss('ptsScoredTm', 'ptsAgst'); break;
            case 'isHome': outputCol = cols.isHome(); break;
            case 'teamLogo': outputCol = cols.teamLogo({ idKey: 'teamId' }); break;
            case 'teamLogoAgst': outputCol = cols.teamLogo({ idKey: 'teamIdAgst' }); break;
            case 'conferenceLogo': outputCol = cols.conferenceLogo({ idKey: 'conferenceId' }); break;
            case 'teamMarket': outputCol = cols.teamMarket({ acc: 'teamMarket', header: 'Team', width: 120, filter: true }); break;
            case 'teamMarketAgst': outputCol = cols.teamMarket({ acc: 'teamMarketAgst', header: 'Opp', width: 120, filter: true }); break;
            case 'blank1': outputCol = cols.blankCol({ width: 15 }); break;
            case 'ptsScoredTm': outputCol = cols.valueOnly('ptsScoredTm', { ptgc: 'player', header: 'Tm', hideFilter: true, unit: 'int', width: 30 }); break;
            case 'ptsAgst': outputCol = cols.valueOnly('ptsAgst', { ptgc: 'player', header: 'Op', hideFilter: true, unit: 'int', width: 30 }); break;
            case 'blank2': outputCol = cols.blankCol({ width: 15 }); break;
            case 'mins': outputCol = cols.valueOnly(isMinsPbp ? 'minsPbp' : 'mins', { ptgc: 'player', className: 'sticky-left left-last' }); break;
            case 'teamLogo2': outputCol = cols.teamLogo({ idKey: 'teamId', className: 'sticky-left' }); break;
            case 'position': outputCol = cols.position(); break;
            case 'jerseyNum': outputCol = cols.jerseyNum(); break;
            case 'classYr': outputCol = cols.classYr(); break;
            case 'fullName': outputCol = cols.playerFullName({ idAcc: 'playerId', nameAcc: 'fullName', header: 'Player', width: 135, className: 'sticky-left player-full-name-width' }); break;
            case 'playerImage': outputCol = cols.playerImage({}); break;
            default: console.log('unrecognized lhsCol in PlayerGameStatsTable: ', col);
        }

        return outputCol;
    });

    const { isMd } = useScreenSize();
    const isStickyLeft = isMd ? false : true;
    const defaultCols = {
        Header: ' ',
        ...(isStickyLeft ? { sticky: 'left' } : {}),
        ...(isStickyLeft ? { className: 'sticky-left left-last' } : {}),
        columns: lhsColsColumns
    };


    const mainConfig = { ptgc: 'player' };
    const cfgL10 = { ptgc: 'player', className: 'border-left pad-left-10' };
    const cfgW40 = { ...mainConfig, width: 40 };
    const cfgL10W40 = { ...mainConfig, width: 40, className: 'border-left pad-left-10' };

    // Create Columns for various table types
    const basicCols = [
        defaultCols,
        {
            Header: 'Traditional Boxscore', columns: [
                cols.valueOnly('ptsScored', { ptgc: 'player' }),
                cols.valueOnly('ast', { ptgc: 'player', width: 36 }),
                cols.valueOnly('orb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('drb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('reb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('stl', { ptgc: 'player', width: 36 }),
                cols.valueOnly('blk', { ptgc: 'player', width: 36 }),
                cols.valueOnly('tov', { ptgc: 'player', width: 36 }),
                cols.valueOnly('pf', { ptgc: 'player', width: 36 }),
                cols.valueOnly('plusMinus', { ptgc: 'player', width: 36 })
                // cols.per, // add to spreadsheet
                // cols.eff, // add to spreadsheet
            ]
        }, {
            Header: 'Traditional Shooting', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('fgm', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga', { ptgc: 'player' }),
                cols.valueAndRating('fgPct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('fgm2', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga2', { ptgc: 'player' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('fgm3', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga3', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('ftm', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fta', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player', width: 80 })
            ]
        }
    ];

    const boxSkinnyCols = [
        defaultCols,
        {
            Header: 'Traditional Boxscore', columns: [
                cols.valueOnly('ptsScored', { ptgc: 'player' }),
                cols.valueOnly('ast', { ptgc: 'player' }),
                cols.valueOnly('orb', { ptgc: 'player' }),
                cols.valueOnly('drb', { ptgc: 'player' }),
                cols.valueOnly('reb', { ptgc: 'player' }),
                cols.valueOnly('stl', { ptgc: 'player' }),
                cols.valueOnly('blk', { ptgc: 'player' }),
                cols.valueOnly('tov', { ptgc: 'player' }),
                cols.valueOnly('pf', { ptgc: 'player' })
            ]
        }, {
            Header: 'Traditional Shooting', className: 'border-left', columns: [
                // cols.valueOnly('fga', { ptgc: 'player' }),
                // cols.valueOnly('fga2', { ptgc: 'player' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player', className: 'border-left' }),
                // cols.valueOnly('fga3', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
                // cols.valueOnly('fta', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player' }),
                cols.valueAndRating('efgPct', { ptgc: 'player' }),
                cols.valueAndRating('tsPct', { ptgc: 'player' })
            ]
        }
    ];

    const tradshotCols = [
        defaultCols,
        { Header: 'Field Goals', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fgPct', mainConfig)
        ] },
        { Header: '2-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm2`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga2`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fg2Pct', mainConfig)
        ] },
        { Header: '3-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm3`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga3`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fg3Pct', mainConfig)
        ] },
        { Header: 'Free Throws', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`ftm`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fta`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('ftPct', mainConfig)
        ] },
        { Header: 'Advanced', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('efgPct', mainConfig),
            cols.valueAndRating('tsPct', mainConfig),
            cols.valueAndRating('fga3Rate', mainConfig)
        ] }
    ];

    const tradshotPbpCols = [
        defaultCols,
        { Header: 'Advanced', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('efgPct', { ...cfgL10 }),
            cols.valueAndRating('fga3Rate', mainConfig)
        ] },
        { Header: 'Field Goals', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm`, { ...cfgL10 }),
            cols.valueOnly(`fga`, { ...mainConfig }),
            cols.valueAndRating('fgPct', mainConfig)
        ] },
        { Header: '2-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm2`, { ...cfgL10 }),
            cols.valueOnly(`fga2`, { ...mainConfig }),
            cols.valueAndRating('fg2Pct', mainConfig)
        ] },
        { Header: '3-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm3`, { ...cfgL10 }),
            cols.valueOnly(`fga3`, { ...mainConfig }),
            cols.valueAndRating('fg3Pct', mainConfig)
        ] },
        ...(isMale ? [{ Header: 'Dunking', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('dunkFgm', { ...cfgL10 }),
            cols.valueOnly('dunkFga', { ...mainConfig }),
            cols.valueOnly('dunkAst', { ...mainConfig }),
            // cols.valueOnly('dunkFgmA', { ...cfgW40 }),
            // cols.valueOnly('dunkFgmU', { ...cfgW40 }),
            // cols.valueOnly('pctAstDunk', { ...mainConfig }),
            cols.valueOnly('layupDunks', { ...mainConfig, width: 45 })
        ] }] : [])
    ];

    const offensiveCols = [
        defaultCols,
        {
            Header: 'Ball Control & Usage', columns: [
                cols.valueAndRating('astPct', { ptgc: 'player' }),
                cols.valueAndRating('astRatio', { ptgc: 'player' }),
                cols.valueAndRating('tovPct', { ptgc: 'player' }),
                cols.valueAndRating('astTov', { ptgc: 'player' }),
                cols.valueAndRating('usagePct', { ptgc: 'player' }),
                cols.valueAndRating('astUsage', { ptgc: 'player' })
            ]
        },
        {
            Header: 'Shooting', className: 'border-left pad-left-10', columns: [
                cols.valueAndRating('tsPct', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player' })
            ]
        },
        {
            Header: 'Play Style', className: 'border-left pad-left-10', columns: [
                cols.valueAndRating('orbPct', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueAndRating('ftaRate', { ptgc: 'player' }),
                cols.valueAndRating('fga3Rate', { ptgc: 'player' })
            ]
        }
    ];

    const defensiveCols = [
        defaultCols,
        ...getDefensiveCols({ ptgc: 'playerGame', isOffense: true, isTotals: true, ratingsType: 'pctiles' })
    ];

    const miscScoringCols = [
        defaultCols,
        ...getMiscScoringCols({ ptgc: 'playerGame', isTotals: true, perWhat: null, ratingsType: 'pctiles' })
    ];

    const zones6Cols = [
        defaultCols,
        { Header: 'At The Rim (u4.5 ft)', columns: [
            cols.valueOnly('atr2Fgm', { ...cfgL10 }),
            cols.valueOnly('atr2Fga', { ...mainConfig }),
            cols.valueAndRating('atr2FgPct', { ...mainConfig })
        ] },
        { Header: 'In The Paint', className: 'border-left', columns: [
            cols.valueOnly('paint2Fgm', { ...cfgL10 }),
            cols.valueOnly('paint2Fga', { ...mainConfig }),
            cols.valueAndRating('paint2FgPct', { ...mainConfig })
        ] },
        { Header: 'Mid Range 2s', className: 'border-left', columns: [
            cols.valueOnly('mid2Fgm', { ...cfgL10 }),
            cols.valueOnly('mid2Fga', { ...mainConfig }),
            cols.valueAndRating('mid2FgPct', { ...mainConfig })
        ] },
        { Header: 'Above Break 3s', className: 'border-left', columns: [
            cols.valueOnly('atb3Fgm', { ...cfgL10 }),
            cols.valueOnly('atb3Fga', { ...mainConfig }),
            cols.valueAndRating('atb3FgPct', { ...mainConfig })
        ] },
        { Header: 'Corner 3s', className: 'border-left', columns: [
            cols.valueOnly('c3Fgm', { ...cfgL10 }),
            cols.valueOnly('c3Fga', { ...mainConfig }),
            cols.valueAndRating('c3FgPct', { ...mainConfig })
        ] }
    ];

    const dists7Cols = [
        defaultCols,
        { Header: 'At The Rim (u4.5 ft)', columns: [
            cols.valueOnly('atr2Fgm', { ...cfgL10 }),
            cols.valueOnly('atr2Fga', { ...mainConfig }),
            cols.valueAndRating('atr2FgPct', { ...mainConfig })
        ] },
        { Header: 'Short 2s (4.5 - 10 ft)', className: 'border-left', columns: [
            cols.valueOnly('sht2Fgm', { ...cfgL10 }),
            cols.valueOnly('sht2Fga', { ...mainConfig }),
            cols.valueAndRating('sht2FgPct', { ...mainConfig })
        ] },
        { Header: 'Medium 2s (10 - 15 ft)', className: 'border-left', columns: [
            cols.valueOnly('med2Fgm', { ...cfgL10 }),
            cols.valueOnly('med2Fga', { ...mainConfig }),
            cols.valueAndRating('med2FgPct', { ...mainConfig })
        ] },
        { Header: 'Long 2s (15+ ft)', className: 'border-left', columns: [
            cols.valueOnly('lng2Fgm', { ...cfgL10 }),
            cols.valueOnly('lng2Fga', { ...mainConfig }),
            cols.valueAndRating('lng2FgPct', { ...mainConfig })
        ] },
        { Header: 'Short 3s (u25 ft)', className: 'border-left', columns: [
            cols.valueOnly('sht3Fgm', { ...cfgL10 }),
            cols.valueOnly('sht3Fga', { ...mainConfig }),
            cols.valueAndRating('sht3FgPct', { ...mainConfig })
        ] },
        { Header: 'Long 3s (25+ ft)', className: 'border-left', columns: [
            cols.valueOnly('lng3Fgm', { ...cfgL10 }),
            cols.valueOnly('lng3Fga', { ...mainConfig }),
            cols.valueAndRating('lng3FgPct', { ...mainConfig })
        ] }
    ];

    const assistZoneCols = [
        defaultCols,
        { Header: 'At The Rim (under 4.5 ft)', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`atr2Ast`, cfgL10),
            cols.valueAndRating(`pctAstAtr2`, mainConfig),
            cols.valueAndRating(`atr2AstP40`, mainConfig)
            // cols.valueAndRating(`atr2AstdPct`, mainConfig)
        ] },
        { Header: 'In The Paint 2s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`paint2Ast`, cfgL10),
            cols.valueAndRating(`pctAstPaint2`, mainConfig),
            cols.valueAndRating(`paint2AstP40`, mainConfig)
            // cols.valueAndRating(`paint2AstdPct`, mainConfig)
        ] },
        { Header: 'Midrange 2s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`mid2Ast`, cfgL10),
            cols.valueAndRating(`pctAstMid2`, mainConfig),
            cols.valueAndRating(`mid2AstP40`, mainConfig)
            // cols.valueAndRating(`mid2AstdPct`, mainConfig)
        ] },
        { Header: 'Above Break 3s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`atb3Ast`, cfgL10),
            cols.valueAndRating(`pctAstAtb3`, mainConfig),
            cols.valueAndRating(`atb3AstP40`, mainConfig)
            // cols.valueAndRating(`atb3AstdPct`, mainConfig)
        ] },
        { Header: 'Corner 3s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`c3Ast`, cfgL10),
            cols.valueAndRating(`pctAstC3`, mainConfig),
            cols.valueAndRating(`c3AstP40`, mainConfig)
            // cols.valueAndRating(`c3AstdPct`, mainConfig)
        ] }
    ];

    const rebByFgaFtaCols = [
        defaultCols,
        { Header: 'ORB off FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`orbFg`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`orbFgPct`, { ptgc: 'player' })
        ] },
        { Header: 'ORB off FTs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`orbFt`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`orbFtPct`, { ptgc: 'player' })
        ] },
        { Header: 'DRB off FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`drbFg`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`drbFgPct`, { ptgc: 'player' })
        ] },
        { Header: 'DRB off FTs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`drbFt`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`drbFtPct`, { ptgc: 'player' })
        ] }
    ];

    const freeThrowCols = [
        defaultCols,
        { Header: '1st Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm1', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta1', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft1Pct', { ptgc: 'player' })
        ] },
        { Header: '2nd Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm2', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta2', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft2Pct', { ptgc: 'player' })
        ] },
        { Header: '3rd Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm3', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta3', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft3Pct', { ptgc: 'player' })
        ] },
        { Header: 'All Free Throws', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ftPct', { ptgc: 'player' })
        ] }
    ];

    const pbpFoulsCols = tableType !== 'pbpFouls' ? [] : [
        defaultCols,
        { Header: 'Shooting Fouls', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('sflDrawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sflDrawnPct`, { ptgc: 'player' }),
            cols.valueOnly('sfl2Drawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt2P', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sfl2DrawnPct`, { ptgc: 'player' }),
            cols.valueOnly('sfl3Drawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt3P', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sfl3DrawnPct`, { ptgc: 'player' })
        ] },
        { Header: 'And 1s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('and1', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`and1Pct`, { ptgc: 'player' })
        ] },
        { Header: 'Floor Fouls', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('fflDrawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`fflDrawnPct`, { ptgc: 'player' })
        ] },
        { Header: 'Charges Drawn', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('opfd', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`opfdPct`, { ptgc: 'player' })
        ] }
    ];

    // DIY columns
    const diyColumns = statsArray.map(row => {
        // check if we have percentiles
        const pctilesArray = tableData.map(d => d[`${row.key}Pctile`]).filter(d => d !== null && typeof d !== 'undefined');
        const hasPctiles = pctilesArray.length > 0;

        // determine column width (when no percentiles)
        const headerChars = dDict?.[row.key]?.label1?.length || 4;
        const width = 12 + (headerChars * 9);

        // return columns
        return hasPctiles
            ? cols.valueAndRating(row.key, mainConfig)
            : cols.valueOnly(row.key, { ...mainConfig, width });
    });
    const diyCols = [
        defaultCols,
        { Header: diyColumns.length >= 3 ? 'Player Stats' : '.', className: 'border-left pad-left-10', columns: [
            cols.blankCol({ width: 12 }),
            ...diyColumns,
            cols.blankCol({ width: 5 })
        ] }
    ];

    // Shooting By Shot Clock
    const subType = tableTypeConfig?.subType || 'oneZone';
    const shotZone = tableTypeConfig?.shotZone || 'atr2';
    const shotZoneName = shotZone === 'atr2' ? 'At The Rim (under 4.5 feet)' : shotZone === 'paint2' ? 'In The Paint 2s' : shotZone === 'mid2' ? 'Mid Range 2s' : shotZone === 'atb3' ? 'Above Break 3s' : shotZone === 'c3' ? 'Corner 3s' : '';
    const clockInterval = tableTypeConfig?.clockInterval || '01'; // S01 == 0 - 10 secs, S12: 10 - 20 secs, S23: 20 - 30 secs
    const clockIntervalName = clockInterval === 'S01' ? '0 - 10 Seconds' : clockInterval === 'S12' ? '10 - 20 Seconds' : clockInterval === 'S23' ? '20 - 30 Seconds' : '';
    const byShotClockCols = (tableType !== 'byShotClock') ? [] :
        subType === 'oneZone' ?
            [
                defaultCols,
                { Header: `${shotZoneName} Shooting By Shot Clock`, className: 'border-left pad-left-10', columns: [
                    { Header: '0 - 10 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS01`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS01`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS01`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS01`, mainConfig)
                    ] },
                    { Header: '10 - 20 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS12`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS12`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS12`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS12`, mainConfig)
                    ] },
                    { Header: '20 - 30 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS23`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS23`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS23`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS23`, mainConfig)
                    ] }
                ] }
            ]
            : subType === 'oneClock' ?
                [
                    defaultCols,
                    { Header: `${clockIntervalName} On Shot Clock Shooting By Zone`, className: 'border-left pad-left-10', columns: [
                        { Header: 'At The Rim (u4.5 ft)', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`atr2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`atr2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`atr2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`atr2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'In The Paint 2s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`paint2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`paint2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`paint2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`paint2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Mid Range 2s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`mid2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`mid2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`mid2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`mid2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Above Break 3s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`atb3Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`atb3Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`atb3FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`atb3FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Corner 3s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`c3Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`c3Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`c3FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`c3FgaFreqClock${clockInterval}`, mainConfig)
                        ] }
                    ] }
                ] : [];

    const singleGameTweetCols = [
        defaultCols,
        { Header: 'Basic Box', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ptsScored', { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueOnly('reb', { ptgc: 'player' }),
            cols.valueOnly('ast', { ptgc: 'player' }),
            cols.valueOnly('stl', { ptgc: 'player' }),
            cols.valueOnly('blk', { ptgc: 'player' }),
            cols.valueOnly('tov', { ptgc: 'player' })
        ] },
        { Header: 'Usage vs TS%', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('usagePct', { ptgc: 'player' }),
            cols.valueAndRating('tsPct', { ptgc: 'player', className: 'border-left pad-left-10' })
        ] },
        { Header: 'Shooting', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fg2Pct', { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
            cols.valueAndRating('ftPct', { ptgc: 'player' })
        ] }
    ];

    // post-game report template for consultin service
    const memphisPostGameCols = tableType !== 'memphisPostGame' ? [] : [
        defaultCols,
        { Header: 'Shooting', className: 'border-left pad-left-10', columns: [
            cols.fgmFga({ fgmKey: `fgm`, fgaKey: `fga`, header: 'FGM/A', width: 54, showPct: true, className: 'pad-left-10' }),
            cols.fgmFga({ fgmKey: `fgm3`, fgaKey: `fga3`, header: '3PM/A', width: 54, showPct: true }),
            cols.fgmFga({ fgmKey: `ftm`, fgaKey: `fta`, header: 'FTM/A', width: 54, showPct: true }),
            cols.valueOnly('layupDunks', { ptgc: 'player', header: 'LayDnk', width: 54, hideFilter: true })
        ] },
        { Header: 'Box Score', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('orb', { ptgc: 'player', width: 39, hideFilter: true, className: 'border-left pad-left-10' }),
            cols.valueOnly('drb', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('reb', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('ast', { ptgc: 'player', width: 39, hideFilter: true }),
            // LU/DUNKS
            cols.valueOnly('tov', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('stl', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('blk', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('pf', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('ptsScored', { ptgc: 'player', width: 43, hideFilter: true })
        ] },
        { Header: 'Advanced', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('efgPct', { ptgc: 'player', width: 78, hideFilter: true, className: 'border-left pad-left-10' }),
            cols.valueAndRating('orbPct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('drbPct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('usagePct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('goodTakeRate', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueOnly('plusMinus', { ptgc: 'player', width: 58, hideFilter: true })
            // FGM-FGA
            // 3PM-3PA
            // FTM-FTA
            // ORB, DRB, TRB, AST, LU/DUNKS, TOV, STL, BLK, PF
            // GAP, PTS, GAP
            // eFG%, ORB%, DRB%, USG%, gTK%, +/-
        ] }
    ];


    // Switch Between Columns
    const columns = React.useMemo(() => {
        switch (tableType) {
            case 'basic': return basicCols;
            case 'boxSkinny': return boxSkinnyCols;
            case 'tradshot': return tradshotCols;
            case 'tradshotPbp': return tradshotPbpCols;
            case 'offensive': return offensiveCols;
            case 'defensive': return defensiveCols;
            case 'miscScoring': return miscScoringCols;
            case 'zones6': return zones6Cols;
            case 'dists7': return dists7Cols;
            case 'assistregion': return assistZoneCols;
            case 'byShotClock': return byShotClockCols;
            case 'rebByFgaFta': return rebByFgaFtaCols;
            case 'freeThrows': return freeThrowCols;
            case 'pbpFouls': return pbpFoulsCols;
            case 'singleGameTweet': return singleGameTweetCols;
            case 'memphisPostGame': return memphisPostGameCols;
            case 'diy': return diyCols;
            default: return [];
        }
    }, [tableData, tableType]);

    const sortObject = React.useMemo(
        () => {
            // First check if explicit sort object provided
            if (sortObj) { return sortObj; }

            // Otherwise determine default sort based on lhsColsGroup
            return { id: 'gameDate', desc: true };
            // : { id: isMinsPbp ? 'minsPbp' : 'mins', desc: true };
            // gotta handle this better, not all should default to gameDate, some to minutes
        },
        [sortObj]
    );


    let thisInfoModal = pageType; // [playerGameRecaps, gameOverview]
    return (
        <ResponsiveTable
            columns={columns}
            tableData={tableData}
            defaultSortObj={sortObject}
            features={features}
            initialPageSize={initialPageSize}
            infoModal={thisInfoModal}
        />
    );
};

export default PlayerGameStatsTable;
