
// Import React Components
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Row, Col, FormControl, Alert } from 'react-bootstrap';
import Select from 'react-select';
import Axios from 'axios';

// Import Data Fetching Stuff
import config from '../../config';
import GlobalContext from '../../context/GlobalContext';
import useCBBQuery from '../../hooks/useCBBQuery';

// Import CBB Utils...
import Logo from '../../components/uiUxItems/Logo';
import NotSubscribed from '../../components/logins/NotSubscribed';
import LoadingSpinner from '../../components/uiUxItems/LoadingSpinner.js';
import { signupSelectStyles } from '../../utils/ReactSelectStyles';

// Payment Stripe
import PaymentMethodDisplay from '../../components/uiUxItems/PaymentMethodDisplay';
import CheckoutFormWrapper from './stripe/CheckoutFormWrapper';
import StartSubscriptionPanel from './stripe/buttons/StartSubscriptionPanel';
import UpdatePaymentCardButton from './stripe/buttons/UpdatePaymentCardButton';
import AutoRenewSubscriptionButton from '../../components/ui/buttons/AutoRenewSubscriptionButton';
import CBBSelect from '../../components/selects/CBBSelect';
import ForgotPassword from './ForgotPassword';
import SendReferral from './SendReferral';


// Create The Component
function MyAccount() {
    const params = useParams();
    const navigate = useNavigate();

    // Grab User Info from Context
    const { userTier, userData, setUserData } = useContext(GlobalContext);
    const isLoggedIn = userData.token ? true : false;
    const trialExpiresAt = userData.user ? userData.user.trialExpiresAt : null;
    const isActiveTrial = trialExpiresAt !== null;
    const defaultTeamId = isLoggedIn ? userData.user.team.teamId : null;
    const teamChangesRemaining = userTier.value >= 2 ? 999 : (isLoggedIn ? userData.user.teamChangesRemaining : 0);
    const noChangesRemaining = teamChangesRemaining === 0;
    const hasValidSubscription = false;

    // useState Set Local State
    const [view, setView] = useState(params.view || 'settings');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [conference, setConference] = useState(null);
    const [team, setTeam] = useState(null);
    const [gender, setGender] = useState(null);
    const [playerPctilesType, setPlayerPctilesType] = useState(null);
    const [shotChartColors, setShotChartColors] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [upgradeToTier, setUpgradeToTier] = useState('User Tier');
    const [gettingUserPayments, setGettingUserPayments] = useState(false);
    const [userPayments, setUserPayments] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [price, setPrice] = useState({ value: 30, label: '$30 / Month', text: '$30 / month (billed monthly)' });
    const [isPriceUpdating, setIsPriceUpdating] = useState(false);
    // different alerts
    const [showAlert, setShowAlert] = useState(false);
    const [updatePlanAlert, setUpdatePlanAlert] = useState(null);

    // constants from state
    const currentPlanAmount = subscription && subscription.plan && subscription.plan.amount ? subscription.plan.amount / 100 : null;

    // Fetch Teams + Conferences Info
    const { data: conferencesInfo = [], isLoading: isLoading1, isError: isError1 } = useCBBQuery({ ep: 'conferences' });
    const { data: teamsInfo = null, isLoading: isLoading2, isError: isError2 } = useCBBQuery({ ep: 'teams' });
    // console.log('MyAccount Data Fetched: ', {teamsInfo, conferencesInfo});
    if (isError1 || isError2) { console.log('Data Fetching Error'); }
    const isLoading = (isLoading1 || isLoading2);

    // Handle Setting Initial States Based On User's Current Settings
    useEffect(() => {
        if (userData.user) {
            setEmail(userData.user.email);
            setFirstName(userData.user.firstName);
            setLastName(userData.user.lastName);
            setTeam(userData.user.team);
            setConference(userData.user.conference);
            setGender(userData.user.gender);
            setPlayerPctilesType(userData.user.playerPctilesType);
            setShotChartColors(userData.user.shotChartColors || { value: 'blueToRed', label: 'Blue (Poor Shooting) to Red (Good Shooting)' });
        }
    }, [userData.user]);

    // // fetch and set subscription
    // const fetchSubscription = async () => {
    //     try {
    //         const authHeader = { headers: { 'x-auth-token': userData.token } };
    //         const response = await Axios.get(`${config.url.API_URL}/stripe/get-subscription`, authHeader);
    //         setSubscription(response.data);
    //     } catch (err) {
    //         console.error('Error fetching subscription:', err);
    //     }
    // };

    // useEffect(() => {
    //     if (userData.token) {
    //         fetchSubscription();
    //     }
    // }, [userData.token]);

    // Submit Handler
    const handleUpdates = async () => {
        setIsUpdating(true);

        // Lets see what we can do
        try {
            // Make Axios Login Request
            const newChangesRemaining = defaultTeamId !== team.value ? teamChangesRemaining - 1 : teamChangesRemaining;
            const updateUser = { firstName, lastName, team, conference, gender, playerPctilesType, shotChartColors, teamChangesRemaining: newChangesRemaining };
            const apiBaseUrl = config.url.API_URL;

            const id = userData.user ? userData.user._id : null;
            const updateResponse = await Axios.put(`${apiBaseUrl}/users/update/${id}`, updateUser);

            // Set States Following Successful Login
            setIsUpdating(false);
            setShowAlert(true);
            setUserData({
                token: userData.token,
                user: updateResponse.data
            });
        } catch (err) {
            setIsUpdating(false);
        }
        setIsUpdating(false);
    };

    let handleUpdatePlan = async () => {
        try {
            // update payment schedule in stripe
            setIsPriceUpdating(true);
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const paymentObject = { subscription, priceKey: price.value };
            const response = await Axios.post(`${config.url.API_URL}/stripe/update-payment-schedule`, paymentObject, authHeader);
            // save updated subscription to state
            setSubscription(response && response.data && response.data.subscription);
            setUpdatePlanAlert({ type: 'success', message: 'Plan updated successfully' });
        } catch (err) {
            console.log('err: ', err);
            setUpdatePlanAlert({ type: 'error', message: 'Failed to update plan. Please try again.' });
        } finally {
            setIsPriceUpdating(false);
            setTimeout(() => setUpdatePlanAlert(null), 8000); // Clear alert after 10 seconds
        }
    };

    // Get all past stripe payments
    const getUserStripePayments = async () => {
        try {
            setGettingUserPayments(true);
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const response = await Axios.get(`${apiBaseUrl}/stripe/get-user-payments`, authHeader);

            const hasPayments = response.data.hasPayments;
            // console.log('get-user-payments response: ', hasPayments ? response.data.paymentIntents.data : response.data);
            setUserPayments(hasPayments ? response.data.paymentIntents.data : []);
            setGettingUserPayments(false);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    // Get user's default Stripe payment method
    const priceDict = {
        30: { text: '$30 / month (billed monthly)' },
        85: { text: '$85 / 3 months (billed once every 3 months)' },
        160: { text: '$160 / 6 months (billed once every 6 months)' },
        299: { text: '$299 / 12 months (billed once every 12 months)' }
    };
    const getUserPaymentMethod = async () => {
        try {
            const apiBaseUrl = config.url.API_URL;
            const authHeader = { headers: { 'x-auth-token': userData.token } };
            const response = await Axios.get(`${apiBaseUrl}/stripe/get-payment-method`, authHeader);

            const hasCard = response.data.hasPaymentMethod;
            // console.log('get-payment-method response: ', response.data);
            setPaymentMethod(hasCard ? response.data.paymentMethod : null);
            setSubscription(hasCard ? response.data.subscription : null);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    // Form Controls, Selects, Buttons + Alerts
    // ==========================================
    // Form Controls (Full Name)
    const firstNameFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name='firstName'
            placeholder='First Name'
        />);
    const lastNameFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name='lastName'
            placeholder='Last Name'
        />);

    // Conference Select
    let conferenceOptions = conferencesInfo
        .sort((a, b) => { return (a.conferenceId < b.conferenceId ? -1 : 1); })
        .map(conf => ({ value: conf.conferenceId, label: conf.conferenceLongName }));
    const conferenceSelect = (
        <Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Conference Select'}
            isDisabled={conferenceOptions.length === 0 || noChangesRemaining || isLoading}
            value={conference}
            onChange={(e) => { setConference(e); setTeam(null); }}
            options={conferenceOptions}
        />);

    // League Select
    const leagueSelect =
        (<Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Select League'}
            value={gender}
            isDisabled={conferenceOptions.length === 0 || noChangesRemaining || isLoading}
            onChange={(e) => { setGender(e); setConference(null); setTeam(null); }}
            options={[{ value: 'MALE', label: `Men's NCAA Basketball` }, { value: 'FEMALE', label: `Women's NCAA Basketball` }]}
        />);

    // Team Select (.map() to match "team" object saved on user model)
    let teamOptions = teamsInfo === null ? [] : teamsInfo
        .filter(row => gender ? row.gender === gender.value : true)
        .filter(row => conference ? row.conferenceId === conference.value : true)
        .filter(row => team && row.teamId === team.value ? false : true)
        .sort((a, b) => a.teamMarket < b.teamMarket ? -1 : 1)
        .map(row => {
            return {
                value: row.teamId,
                label: `${row.teamMarket} ${row.teamName}`,
                teamId: row.teamId,
                teamMarket: row.teamMarket,
                teamName: row.teamName,
                gender: row.gender,
                conferenceId: row.conferenceId,
                divisionId: row.divisionId
            };
        });
    const teamSelect =
        (<Select
            className='cbb-select'
            styles={signupSelectStyles}
            placeholder={'Select Team'}
            value={team}
            onChange={setTeam}
            options={teamOptions}
            isDisabled={conferenceOptions.length === 0 || gender === null || noChangesRemaining || isLoading}
        />);

    const playerPctilesTypeSelect1 =
        (<CBBSelect
            selectType='playerPctileTypes'
            stylesType='signup'
            value={playerPctilesType}
            setValue={setPlayerPctilesType}
        />);
    const shotChartColorsSelect =
        (<CBBSelect
            selectType='shotChartColors'
            stylesType='signup'
            value={shotChartColors}
            setValue={setShotChartColors}
        />);

    // Button and Select for Updating Plan (30, 85, 160, 299)
    let isSamePrice = price.value === currentPlanAmount;
    let updatePlanButton =
        (<span
            className={`myaccount-button ${isPriceUpdating || isSamePrice ? 'disabled' : ''}`}
            style={{ width: 80, fontSize: '0.875em', marginRight: 3, marginTop: 3, paddingTop: 2, cursor: 'pointer' }}
            onClick={() => handleUpdatePlan()}
        >
            {isPriceUpdating ? 'Updating...' : isSamePrice ? 'Change Plan' : 'Update Plan'}
        </span>);

    let updatePlanAlertMessage =
        (<div
            className={`alert ${updatePlanAlert?.type === 'success' ? 'alert-success' : 'alert-danger'}`}
            style={{
                marginTop: 3,
                padding: '6px 15px',
                borderRadius: 5,
                animation: `fadeOut 8s forwards`
            }}
        >
            {updatePlanAlert?.message}
        </div>);

    let priceSelect = (<>
        <Row style={{ margin: 0, marginBottom: 2 }}>
            <CBBSelect
                selectType='subscriptionPackages'
                value={price}
                setValue={setPrice}
                stylesType='small'
                isDisabled={isPriceUpdating}
            />
            {updatePlanButton}
        </Row>

    </>);

    // Submit Button
    let isFirstNameValid = typeof (firstName) !== 'undefined' && firstName.length >= 2;
    let isLastNameValid = typeof (lastName) !== 'undefined' && lastName.length >= 2;
    let isSubmitDisabled = !(isFirstNameValid && isLastNameValid && team && gender && conference);
    const submitButton =
        (<div
            className={`cbb-submit-button ${isSubmitDisabled ? 'disabled' : ''} ${showAlert ? 'faded' : ''}`}
            style={{ width: 225, textAlign: 'center' }}
            onClick={handleUpdates}
        >
            {`${isUpdating ? 'Profile Updating...' : 'Submit Updated Profile'}`}
        </div>);

    const alertStyles = showAlert ? {} : { opacity: 0, visibility: 'hidden', pointerEvents: 'none' };
    const successAlert =
        (<Alert
            className={`modal-alert ${showAlert ? 'animate-fade-away' : ''}`}
            style={{ marginTop: 5, width: 225, textAlign: 'center', ...alertStyles }}
            variant='success'
            onAnimationEnd={() => setShowAlert(false)}
        >
            Your profile was updated
        </Alert>);

    // Logout Button
    const handleLogout = () => {
        // eslint-disable-next-line no-undefined
        setUserData({ token: undefined, user: undefined });
        localStorage.setItem('auth-token', '');
        navigate('/');
    };
    const logoutButton =
        (<div
            className={`cbb-submit-button dark-red ${showAlert ? 'faded' : ''}`}
            style={{ width: 225, textAlign: 'center' }}
            onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Are you sure? To log back in, you will need your account password.')) {
                    handleLogout();
                }
            }}
        >
            {`${isUpdating ? 'Logging Out' : 'Log Out'}`}
        </div>);

    // Team & Conference Logos
    const teamLogo =
        (<div style={{ marginLeft: 12 }}>
            <Logo
                className='logo-sticker'
                style={{ width: 60, height: 60, display: 'flex', alignItems: 'center' }}
                ptgc='team'
                teamId={team ? team.value : null}
            />
        </div>);
    const confLogo =
        (<Logo
            className='logo-sticker'
            style={{ width: 60, maxHeight: 70, display: 'flex', alignItems: 'center' }}
            ptgc='conference'
            conferenceId={conference ? conference.value : null}
        />);
    // ======

    // Main Section Buttons
    // ====
    // button options (referral is only shown if user has active subscription)
    let pageNames = ['Settings', 'Subscribe', 'Orders', 'Update Card', 'Password', 'Referral'];

    // create the buttons
    let pageButtons = pageNames.map(tab => {
        let tabView = tab.toLowerCase().replace(/\s+/g, '-');
        return (
            <Col key={tab} xs={6} sm={2} style={{ padding: 0, width: '100%' }}>
                <span
                    style={{ fontSize: '1.1em', height: 35, padding: 5, marginBottom: 2, width: '100%', display: 'block', border: 'none' }}
                    className={`myaccount-button${tabView === view ? ' selected' : ''}`}
                    onClick={() => {
                        setView(tabView);
                        let newPath = `/my-account/${tabView}`; // replace space with dash
                        navigate(newPath);
                    }}
                >
                    {tab}
                </span>
            </Col>
        );
    });

    // User or Pro Tier Buttons
    const tierOptions = ['User Tier', 'Pro Tier'];
    const userTierProTierButtons = (
        <Col xs={12} sm={6}>
            <Row style={{ marginLeft: -1, marginRight: -1 }}>
                {tierOptions.map(d => {
                    return (
                        <Col key={d} xs={6} style={{ width: '100%', paddingRight: 1, paddingLeft: 1 }}>
                            <span
                                key={d}
                                className={`cbb-button ${d === upgradeToTier ? 'selected' : ''} ${isPriceUpdating ? 'disabled' : ''}`}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => setUpgradeToTier(d)}
                            >
                                {d}
                            </span>
                        </Col>
                    );
                })}
            </Row>
        </Col>
    );

    // Fetch Payment Method & Payments as needed
    useEffect(() => {
        // if user is not logged in, we don't/can't fetch their payments data.
        if (!userData.token) { return; }
        if (view === 'orders') { getUserStripePayments(); }
        if (view === 'update-card') { getUserPaymentMethod(); }
        if (view === 'subscribe') { getUserPaymentMethod(); }
    }, [view, userData.token, userTier]);


    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let pastOrders = (<div style={{ maxWidth: 'max-content' }}>
        {userPayments.map((row, idx) => {
            let billDay = new Date(row.created * 1000);
            let dayString = `${monthNames[billDay.getMonth()]} ${billDay.getDate()}, ${billDay.getFullYear()}`;
            return (
                <div key={`payment-${idx}`} style={{ padding: '5px 10px', lineHeight: 1.3, border: '1px solid #222', borderRadius: 5, marginBottom: 5, background: 'darkgreen', opacity: 0.75 }}>
                    <p style={{ fontWeight: 700, fontSize: '0.9em', color: 'white' }}>
                        User Tier: Monthly ${row.amount / 100},
                        <span style={{ fontWeight: 300, fontSize: '0.9em', marginLeft: 4 }}>on {dayString}</span>
                    </p>
                </div>
            );
        })}
    </div>);

    // Email Links
    let nickEmailLink1 = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Questions on User Tier`}>nick@cbbanalytics.com</a>;
    let nickEmailLink2 = <a style={{ color: '#0066CC' }} href={`mailto:nick@cbbanalytics.com?subject=Quote for Pro Tier`}>nick@cbbanalytics.com</a>;
    let sectionRowStyles = { borderBottom: '2px solid #222222', margin: 0, paddingTop: 8, justifyContent: 'space-between', alignItems: 'flex-end' };

    // Trial Time Remaining
    let now = new Date();
    let zed = new Date(trialExpiresAt);
    let minsRemaining = ((zed - now) / (1000 * 60));
    let diffValue, diffUnits;
    if (minsRemaining / 1440 >= 2) {
        diffValue = Math.floor(minsRemaining / 1440); diffUnits = 'day';
    } else if (minsRemaining / 60 >= 1) {
        diffValue = Math.floor(minsRemaining / 60); diffUnits = 'hour';
    } else { diffValue = Math.floor(minsRemaining); diffUnits = 'minute'; }
    let diffText = diffValue === 1 ? `is ${diffValue} ${diffUnits}` : `are ${diffValue} ${diffUnits}s`;


    // The Return!
    // let showAutoRenewToggleSwitch = (userTier.value === 2 || userTier.value === 9) && (subscription && subscription.status !== 'canceled');
    let showAutoRenewToggleSwitch = subscription && subscription?.status !== 'cancelled'; // do we want to show too cancelled?
    // console.log('showAutoRenewToggleSwitch:', { showAutoRenewToggleSwitch, subscription, status: subscription?.status });
    if (!isLoggedIn) { return <NotSubscribed errorType='needsTier1' />; }

    return (
        <Container className='my-account-container'>
            <Row className='my-account-button-row'>
                <Row style={{ width: 750, marginRight: 15, marginLeft: 15, justifyContent: 'space-between' }}>
                    {pageButtons}
                </Row>
            </Row>
            <Container className='my-account-inner-container'>

                {/* ====== SECTION 1: User Settings ====== */}
                {view === 'settings' && <>
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            {email ? `Update User Profile, ${email}` : 'Update User Profile'}
                        </h3>
                    </Row>
                    <Row style={{ position: 'absolute', top: '-25px', right: '30px' }}>
                        <div style={{ display: 'flex' }}>
                            {conference && confLogo}
                            {team && teamLogo}
                        </div>
                    </Row>
                    <Row style={{ marginTop: 8, justifyContent: 'center' }}>
                        <Col xs={12} sm={6} style={{ maxWidth: 375 }}>
                            <h3 className='select-header' style={{ marginTop: 8 }}>First Name</h3>
                            {firstNameFormControl}
                            {!isFirstNameValid && <h3 className='form-error-header'>...first name too short</h3>}

                            <h3 className='select-header' style={{ marginTop: 8 }}>Last Name</h3>
                            {lastNameFormControl}
                            {!isLastNameValid && <h3 className='form-error-header'>...last name too short</h3>}

                            <h3 className='select-header' style={{ marginTop: 8 }}>Player Percentiles</h3>
                            {/* {playerPctilesTypeSelect} */}
                            {playerPctilesTypeSelect1}

                            <h3 className='select-header' style={{ marginTop: 8 }}>Shot Chart Colors</h3>
                            {shotChartColorsSelect}
                        </Col>
                        <Col xs={12} sm={6} style={{ maxWidth: 375 }}>
                            <h3 className='select-header' style={{ marginTop: 8 }}>Default League</h3>
                            {leagueSelect}
                            {!gender && <h3 className='form-error-header'>...select a league</h3>}

                            <h3 className='select-header' style={{ marginTop: 8 }}>Default Conference</h3>
                            {conferenceSelect}
                            {!conference && <h3 className='form-error-header'>...select a conference</h3>}

                            <h3 className='select-header' style={{ marginTop: 8 }}>Default Team</h3>
                            {teamSelect}
                            {!team && <h3 className='form-error-header'>...select a team</h3>}
                        </Col>
                    </Row>
                    {!hasValidSubscription &&
                        <Row style={{ marginLeft: 0 }}>
                            <p style={{ marginTop: 15 }}>
                                Trial accounts are limited to changing the default team associated with their account a maximum of 5 times.
                                This account has <span style={{ fontWeight: 700, color: '#0066CC' }}>{teamChangesRemaining} team update remaining.</span>
                            </p>
                        </Row>
                    }
                    <Row style={{ marginTop: 25, justifyContent: 'center' }}>
                        {submitButton}
                    </Row>
                    <Row style={{ marginTop: 5, justifyContent: 'center' }}>
                        {logoutButton}
                    </Row>
                </>}

                {/* ====== SECTION 2: Payments (Subscribe) ====== */}
                {view === 'subscribe' && <>
                    {/* Top of Section Info */}
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            Account Subscription
                        </h3>
                    </Row>
                    <Row style={{ margin: 0, marginBottom: 8, marginTop: 12 }}>
                        <p style={{ lineHeight: 1.3 }}>
                            This account is currently signed up for
                            <span style={{ marginLeft: 4, marginRight: 4, color: '#0066CC', fontSize: '1.025em', fontWeight: 700 }}>
                                {userTier.label}.
                            </span>
                            {isActiveTrial && <>
                                This is a trial account, and there
                                <span style={{ marginLeft: 4, marginRight: 4, color: '#0066CC', fontSize: '1.025em', fontWeight: 700 }}>
                                    {diffText}
                                </span>
                                remaining on your trial.
                            </>}
                        </p>
                    </Row>

                    {/* Payment Tabs Row */}
                    <Row style={{ marginBottom: 12 }}>
                        {userTierProTierButtons}
                    </Row>

                    {/* User Tier (Tier 2) Subscriptions */}
                    {upgradeToTier === 'User Tier' && <>
                        <p style={{ lineHeight: 1.35, marginBottom: 5 }}>
                            <strong style={{ color: '#0066CC' }}>Upgrade to {upgradeToTier}: </strong>
                            for fans, writers, sports bettors, and D-II and D-III coaching staffs. User Tier provides full access to
                            all D-II and D-III stats, and access to our basic D-I stats package for all teams.
                            {[0, 1].includes(userTier.value) && ' All first time User Tier subscriptions come with a free 1-week trial. Email '}
                            {[0, 1].includes(userTier.value) && nickEmailLink1}
                            {[0, 1].includes(userTier.value) && ' with questions, comments or concerns before subscribing. A subscription is $30 / month, with discounts for longer subscription intervals.'}
                        </p>
                        <CheckoutFormWrapper>
                            <StartSubscriptionPanel
                                subscription={subscription}
                                paymentMethod={paymentMethod}
                                style={{ marginTop: 20 }}
                            />
                        </CheckoutFormWrapper>

                        {/* Toggle Switch - -Show for Tier 2 (and Admin) Users with non-cancelled subscription */}
                        {showAutoRenewToggleSwitch &&
                            <AutoRenewSubscriptionButton
                                subscription={subscription}
                                setParentSubscription={async (e) => setSubscription(e)}
                                isDisabled={isPriceUpdating}
                                style={{ paddingTop: 8, marginTop: 8, maxWidth: 320, borderTop: '1px solid #888888' }}
                            />
                        }
                        {/* NC: want to show a <loadingspinner  */}

                        {[30, 85, 160, 299].includes(currentPlanAmount) && subscription.status !== 'canceled' && <>
                            <Row style={{ margin: 0, marginTop: 5, paddingTop: 15, fontSize: '0.875em', borderTop: '1px solid #888' }}>
                                <p style={{ lineHeight: 1.35 }}>Your current plan charges
                                    <strong style={{ color: 'darkgreen', paddingLeft: 3 }}>{priceDict[currentPlanAmount]?.text}</strong>
                                . You can update your billing cycle at any time, and you will not be charged until your next payment is due. To cancel your account, simply turn off the auto-renew. Your card will no longer be charged, and you will keep your account through the current paid-for period.</p>
                            </Row>
                            <Row style={{ margin: 0, marginTop: 10 }}>
                                {priceSelect}
                            </Row>
                            {updatePlanAlert &&
                                <Row style={{ margin: 0, marginBottom: 2 }}>
                                    {updatePlanAlertMessage}
                                </Row>
                            }
                        </>}
                    </>}

                    {/* Pro Tier (Tier 4) Subscriptions */}
                    {upgradeToTier === 'Pro Tier' && <>
                        <p style={{ lineHeight: 1.35, marginBottom: 5 }}>
                            <strong style={{ color: '#0066CC' }}>Upgrade to {upgradeToTier}: </strong>
                            For Division-I coaching staffs, NBA and WNBA front offices and scouts, Agents and Large Media. Reach out to {nickEmailLink2} for a quote. Pro Tier includes
                            access to everything we have, which includes:
                        </p>
                        <ol style={{ margin: 0, marginBottom: 20, lineHeight: 1.4 }}>
                            <li>the full collection of stats for all D-I teams, all players, all games</li>
                            <li>postgame reports available 30 minutes after games end</li>
                            <li>ability to download data from all tables across the platform</li>
                            <li>direct contact with Nick and the CBB team throughout the year</li>
                            <li>access to our transfer portal section</li>
                        </ol>
                    </>}
                </>}

                {/* ====== SECTION 3: Past Orders ====== */}
                {view === 'orders' && <>
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            Latest Subscription Payments
                        </h3>
                    </Row>
                    <Row style={{ margin: 0, marginTop: 12 }}>
                        {gettingUserPayments && <LoadingSpinner size='small' text='fetching user payments...' />}
                        {!gettingUserPayments && pastOrders}
                    </Row>
                </>}

                {/* ====== SECTION 4: Update Credit Card ====== */}
                {view === 'update-card' && <>
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            Update Credit Card
                        </h3>
                    </Row>
                    <Row style={{ margin: 0, marginTop: 12 }}>
                        <PaymentMethodDisplay
                            paymentMethod={paymentMethod}
                            userTier={userTier}
                        />
                    </Row>
                    <Row style={{ margin: 0, marginTop: 20 }}>
                        <CheckoutFormWrapper>
                            <UpdatePaymentCardButton
                                style={{ maxWidth: 320 }}
                                currentPaymentMethod={paymentMethod}
                                onPaymentMethodUpdated={async (e) => setPaymentMethod(e)}
                            />
                        </CheckoutFormWrapper>
                    </Row>
                </>}

                {/* ====== SECTION 5: Reset Password ====== */}
                {view === 'password' && <>
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            Reset Password
                        </h3>
                    </Row>
                    <ForgotPassword />
                </>}

                {/* ====== SECTION 6: Referral ====== */}
                {view === 'referral' && <>
                    <Row style={{ ...sectionRowStyles }}>
                        <h3 style={{ fontWeight: 700, fontSize: 'calc(17px + 0.35vw)', color: '#222222' }}>
                            Referral
                        </h3>
                    </Row>
                    <SendReferral />
                </>}

                {/* Alerts Below Sections */}
                <Row style={{ justifyContent: 'center' }}>
                    {successAlert}
                </Row>
            </Container>
        </Container>
    );
}

export default MyAccount;
// 638
