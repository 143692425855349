
import cols from '../utils/ColumnsDict';

// same set of  props to each of these?
// ptgc, isTotals, isOffense, ratingsType, perWhat, isMale

// Traditional Defensive Stats
const getDefensiveCols = ({ ptgc = 'teamGame', isOffense = true, ratingsType = 'pctiles', isTotals, perWhat }) => {
    // configs for layout
    const configPtgc = ['teamGame', 'teamAgg'].includes(ptgc) ? 'team' : 'player';
    const cfgMain = { ptgc: configPtgc, isOffense, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        {
            Header: 'Defense Overall', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('drb', cfgL10),
                cols.valueAndRating('drbPct', cfgMain),
                cols.valueOnly('stl', cfgL10),
                cols.valueAndRating('stlPct', cfgMain),
                cols.valueAndRating('stlTov', cfgMain),
                cols.valueOnly('blk', cfgL10),
                cols.valueAndRating('blkPct', cfgMain),
                cols.valueAndRating('hkmPct', cfgMain)
            ]
        }, {
            Header: 'Foul Tendencies', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('pf', cfgL10),
                ...(isTotals ? [] : [cols.valueAndRating(`pf${perWhat}`, cfgMain)]),
                cols.valueAndRating('pfEff', cfgMain),
                cols.valueAndRating('stlPerPf', cfgMain),
                cols.valueAndRating('blkPerPf', cfgMain)
            ]
        }
    ];
};

// Shot Context: shooting by half court, transition, putbacks
const getShotContextCols = ({ ptgc = 'teamGame', isOffense = true, ratingsType = 'pctiles' }) => {
    // ptgc:    one of ['teamGame', 'teamAgg']

    // configs for layout
    const configPtgc = ['teamGame', 'teamAgg'].includes(ptgc) ? 'team' : 'player';
    const cfgMain = { ptgc: configPtgc, isOffense, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'Half Court', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqHc', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fga2Hc', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg2PctHc', cfgMain),
            cols.valueOnly('fga3Hc', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg3PctHc', cfgMain)
        ] },
        { Header: 'Transition', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqTr', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fga2Tr', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg2PctTr', cfgMain),
            cols.valueOnly('fga3Tr', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg3PctTr', cfgMain)
        ] },
        { Header: 'Putbacks', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqPb', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fgaPb', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fgPctPb', cfgMain)
        ] }
    ];
};

// Chance Context: efficiency/frequency by half court, transition, putbacks
const getChncContextCols = ({ ptgc = 'team', isOffense = true, ratingsType = 'pctiles' }) => {
    // configs for layout
    const cfgMain = { ptgc, isOffense, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'Half Court', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncHc', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncHc', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcHc', cfgMain),
            cols.valueAndRating('freqChncHc', cfgMain)
        ] },
        { Header: 'Transition', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncTr', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncTr', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcTr', cfgMain),
            cols.valueAndRating('freqChncTr', cfgMain)
        ] },
        { Header: 'Putbacks', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncPb', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncPb', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcPb', cfgMain),
            cols.valueAndRating('freqChncPb', cfgMain)
        ] }
    ];
};

// Misc Shooting: assisted vs unassisted by all, 2s, 3s, rim, dunks
const getAstdUnastdCols = ({ isTotals = false, perWhat = '', ratingsType = 'pctiles', isMale = true }) => {
    // configs for layout
    const cfgMain = { perWhat, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };
    const cfgW45 = { ...cfgMain, width: 45 };
    const cfgL10W45 = { ...cfgL10, width: 45, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'All Shots', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgmA', cfgL10W45)] : [cols.valueAndRating(`fgmA${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgmU', cfgW45)] : [cols.valueAndRating(`fgmU${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgmAstdPct', cfgMain)
        ] },
        { Header: '2-Pointers', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgm2A', cfgL10W45)] : [cols.valueAndRating(`fgm2A${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgm2U', cfgW45)] : [cols.valueAndRating(`fgm2U${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgm2AstdPct', cfgMain)
        ] },
        { Header: '3-Pointers', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgm3A', cfgL10W45)] : [cols.valueAndRating(`fgm3A${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgm3U', cfgW45)] : [cols.valueAndRating(`fgm3U${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgm3AstdPct', cfgMain)
        ] },
        { Header: 'At Rim', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('atr2FgmA', cfgL10W45)] : [cols.valueAndRating(`atr2FgmA${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('atr2FgmU', cfgW45)] : [cols.valueAndRating(`atr2FgmU${perWhat}`, cfgMain)]),
            cols.valueAndRating('atr2AstdPct', cfgMain)
        ] },
        ...(isMale ? [{
            Header: 'Dunks', className: 'border-left pad-left-10', columns: [
                ...(isTotals ? [cols.valueOnly('dunkFgmA', cfgL10W45)] : [cols.valueAndRating(`dunkFgmA${perWhat}`, cfgL10)]),
                ...(isTotals ? [cols.valueOnly('dunkFgmU', cfgW45)] : [cols.valueAndRating(`dunkFgmU${perWhat}`, cfgMain)]),
                cols.valueAndRating('dunkAstdPct', cfgMain)
            ]
        }] : [])
    ];
};

// foul drawing metrics (charges, SFLs)
const getPbpFoulsCols = ({ isTotals = false, perWhat = '', ratingsType = 'pctiles' }) => {
    const cfgMain = { perWhat, ratingsType };
    const cfgW45 = { ...cfgMain, width: 45 };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };
    const cfgL10W45 = { ...cfgL10, width: 45, className: 'border-left pad-left-10' };

    return [
        { Header: 'Shooting Fouls', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('sflDrawn', cfgL10W45)] : [cols.valueAndRating(`sflDrawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt', cfgW45)] : []),
            cols.valueAndRating(`sflDrawnPct`, cfgMain),
            ...(isTotals ? [cols.valueOnly('sfl2Drawn', cfgL10W45)] : [cols.valueAndRating(`sfl2Drawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt2P', cfgW45)] : []),
            cols.valueAndRating(`sfl2DrawnPct`, cfgMain),
            ...(isTotals ? [cols.valueOnly('sfl3Drawn', cfgL10W45)] : [cols.valueAndRating(`sfl3Drawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt3P', cfgW45)] : []),
            cols.valueAndRating(`sfl3DrawnPct`, cfgMain)
        ] },
        { Header: 'And 1s', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('and1', cfgL10W45)] : [cols.valueAndRating(`and1${perWhat}`, cfgL10)]),
            cols.valueAndRating(`and1Pct`, cfgMain)
        ] },
        { Header: 'Floor Fouls', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fflDrawn', cfgL10W45)] : [cols.valueAndRating(`fflDrawn${perWhat}`, cfgL10)]),
            cols.valueAndRating(`fflDrawnPct`, cfgMain)
        ] },
        { Header: 'Charges Drawn', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('opfd', cfgL10W45)] : [cols.valueAndRating(`opfd${perWhat}`, cfgL10)]),
            cols.valueAndRating(`opfdPct`, cfgMain)
        ] }
    ];
};

// misc scoring (fb, pitp, scp, potov, bench)
const getMiscScoringCols = ({ ptgc = 'teamGame', isTotals = false, perWhat = '', ratingsType = 'pctiles' }) => {
    // configs for layout
    const configPtgc = ['teamGame', 'teamAgg'].includes(ptgc) ? 'team' : 'player';
    const isGame = ['teamGame', 'playerGame'].includes(ptgc);
    const cfgMain = { perWhat, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };
    const cfgW45 = { ...cfgMain, width: 45 };
    const cfgL10W45 = { ...cfgL10, width: 45, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        {
            Header: configPtgc === 'team' ? 'Offense' : '-', className: 'border-left pad-left-10', columns: [
                ...(ptgc !== 'teamGame' ? [cols.valueOnly('ptsScored', cfgL10W45)] : []),
                ...(configPtgc === 'team' ? [cols.valueAndRating('ortg', cfgMain)] : [])
            ]
        }, {
            Header: 'Points In The Paint', className: 'border-left pad-left-10', columns: [
                ...(isGame ? [cols.valueAndRating(`pitp`, cfgMain)] : [cols.valueOnly('pitp', cfgL10W45)]), // valueOnly vs valueAndRating
                ...(isTotals ? [] : [cols.valueAndRating(`pitp${perWhat}`, cfgMain)]),
                cols.valueAndRating(`pitpPctPts`, cfgMain)
            ]
        }, {
            Header: 'Fast Break Points', className: 'border-left pad-left-10', columns: [
                ...(isGame ? [cols.valueAndRating(`fbpts`, cfgMain)] : [cols.valueOnly('fbpts', cfgL10W45)]), // valueOnly vs valueAndRating
                ...(isTotals ? [] : [cols.valueAndRating(`fbpts${perWhat}`, cfgMain)]),
                cols.valueAndRating(`fbptsPctPts`, cfgMain)
            ]
        }, {
            Header: '2nd Chance Points', className: 'border-left pad-left-10', columns: [
                ...(isGame ? [cols.valueAndRating(`scp`, cfgMain)] : [cols.valueOnly('scp', cfgL10W45)]), // valueOnly vs valueAndRating
                ...(isTotals ? [] : [cols.valueAndRating(`scp${perWhat}`, cfgMain)]),
                cols.valueAndRating(`scpPctPts`, cfgMain)
            ]
        }, ...(configPtgc === 'team' ? [{
            Header: 'Other', className: 'border-left pad-left-10', columns: [
                ...((isTotals && !isGame) ? [cols.valueOnly('potov', cfgL10W45)] : [cols.valueAndRating(`potov${perWhat}`, cfgL10)]),
                ...((isTotals && !isGame) ? [cols.valueOnly('benchPts', cfgW45)] : [cols.valueAndRating(`benchPts${perWhat}`, cfgMain)])
            ]
        }] : [])
        // blkd, blkdPg, ...
        // opf, opfPg, ...
    ];
};


// and export
export {
    // boxscore derived
    getDefensiveCols,
    // pbp derived
    getShotContextCols,
    getChncContextCols,
    getAstdUnastdCols,
    getPbpFoulsCols,
    getMiscScoringCols
};
