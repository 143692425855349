// not used yet, but want to do a good job with this, somehow.
// may need to update this with latest changes to the splits

const { arrayToObject } = require('../utils/ReshapeData');

const splitGroups = {
    all: ['l5g', 'l10g', 'season', 'seasonAll', 'confReg', 'confAll', 'nonconfReg', 'post', 'home', 'away', 'neutral', 'h1', 'h2', 'q1', 'q2', 'q3', 'q4', 'ot', 'wins', 'losses', 'quad1', 'quad2', 'quad3', 'quad4', 'daterange', 'custom', 'tourney', 'conferenceAvg', 'divisionAvg'],
    noDivConfAvg: ['l5g', 'l10g', 'season', 'seasonAll', 'confReg', 'confAll', 'nonconfReg', 'post', 'home', 'away', 'neutral', 'h1', 'h2', 'q1', 'q2', 'q3', 'q4', 'ot', 'wins', 'losses', 'quad1', 'quad2', 'quad3', 'quad4', 'daterange', 'custom', 'tourney'],
    hmm: ['l5g', 'l10g', 'season', 'seasonAll', 'confReg', 'confAll', 'nonconfReg', 'post', 'home', 'away', 'neutral', 'q1', 'q2', 'q3', 'q4', 'h1', 'h2', 'ot', 'wins', 'losses', 'quad1', 'quad2', 'quad3', 'quad4', 'daterange'],
    og4: ['l5g', 'l10g', 'season', 'confReg']
};

const splits = [
    { value: 'l5g', label: 'Last 5 Games', label2: 'Last 5', shortLabel: 'L5G' },
    { value: 'l10g', label: 'Last 10 Games', label2: 'Last 10', shortLabel: 'L10G' },
    { value: 'season', label: 'Full Season', label2: 'Season', shortLabel: 'Full', isDefault: true },
    { value: 'seasonAll', label: 'All Official Games', label2: 'All Games', shortLabel: 'All' },
    { value: 'confReg', label: 'In-Conference (Reg)', label2: 'Conf (Reg)', shortLabel: 'ConfR' },
    { value: 'confAll', label: 'In-Conference (All)', label2: 'Conf (All)', shortLabel: 'ConfA' },
    { value: 'nonconfReg', label: 'Non-Conference (Excl Postseason)', label2: 'NonConf (Reg)', shortLabel: 'NonC' },
    { value: 'nonconfAll', label: 'Non-Conference (Inc Postseason)', label2: 'NonConf (All)', shortLabel: 'NonCA' },
    { value: 'post', label: 'Postseason', label2: 'Postseason', shortLabel: 'Post' },
    { value: 'home', label: 'Home Games', label2: 'Home', shortLabel: 'Home' },
    { value: 'away', label: 'Away Games', label2: 'Away', shortLabel: 'Away' },
    { value: 'neutral', label: 'Neutral Games', label2: 'Neutral', shortLabel: 'Neut' },
    { value: 'wins', label: 'Games Won', label2: 'Games Won', shortLabel: 'Wins' },
    { value: 'losses', label: 'Games Lost', label2: 'Games Lost', shortLabel: 'Loss' },
    { value: 'clutch', label: 'Clutch Stats', label2: 'Clutch', shortLabel: 'CTH' },
    { value: 'q1', label: '1st Quarter', label2: '1st Quarter', shortLabel: 'Q1' },
    { value: 'q2', label: '2nd Quarter', label2: '2nd Quarter', shortLabel: 'Q2' },
    { value: 'q3', label: '3rd Quarter', label2: '3rd Quarter', shortLabel: 'Q3' },
    { value: 'q4', label: '4th Quarter', label2: '4th Quarter', shortLabel: 'Q4' },
    { value: 'h1', label: '1st Half', label2: '1st Half', shortLabel: 'H1' },
    { value: 'h2', label: '2nd Half', label2: '2nd Half', shortLabel: 'H2' },
    { value: 'ot', label: 'Overtime', label2: 'Overtime', shortLabel: 'OT' },
    { value: 'quad1', label: 'Quad 1 Games', label2: 'Quad 1', shortLabel: 'Quad1' },
    { value: 'quad2', label: 'Quad 2 Games', label2: 'Quad 2', shortLabel: 'Quad2' },
    { value: 'quad3', label: 'Quad 3 Games', label2: 'Quad 3', shortLabel: 'Quad3' },
    { value: 'quad4', label: 'Quad 4 Games', label2: 'Quad 4', shortLabel: 'Quad4' },
    { value: 'daterange', label: 'Date Range', label2: 'Date Range', shortLabel: 'Date' },
    { value: 'custom', label: 'Select Your Own Game', label2: 'Custom', shortLabel: 'CS' },
    { value: 'game', label: 'Single Game', label2: 'Single Game', shortLabel: 'Game' },
    { value: 'conferenceAvg', label: 'Conference Average', label2: 'Conf Avg', shortLabel: 'cAvg' },
    { value: 'divisionAvg', label: 'Division Average', label2: 'Div Avg', shortLabel: 'dAvg' }
];

const splitsObj = arrayToObject({ array: splits, key: 'value' });

// and export
module.exports = { splits, splitsObj, splitGroups };
